import i18n from '@/i18n'

export default {
  name: 'ReminderTypeDialogComponent',
  props: {
    showDialog: Boolean,
    edit: Boolean,
    reminderType: Object
  },
  data: () => ({
    validForm: false,
    validationRules: {
      required: (value) => !!value || `${i18n.t('required')}`,
      expirationRequired: (value) => (value.expiration.hours.applies || value.expiration.km.applies || value.expiration.date.applies) ||
      `${i18n.t('reminder.type.expirationRequired')}`,
      noNegative: (value, parent) => !parent || !value || parseFloat(value) >= 0 || `${i18n.t('maxValue', { max: 0 })}`
    }
  }),
  computed: {
  },
  created () {
  },
  methods: {
    cancel () {
      this.$emit('closeDialog')
      this.$refs.form.resetValidation()
    },
    save () {
      this.$emit('saveDialog', this.reminderType)
      this.$refs.form.resetValidation()
    }
  }
}
